export const WORD_LENGTH = 5

export const WORDS = [
  'birds',
  'hammy',
  'furry',
  'meows',
  'whisk',
  'tails',
  'claws',
  'licks',
  'hides',
  'fluff',
  'snugs',
  'cages',
  'barks',
  'yelps',
  'fetch',
  'leash',
  'treat',
  'scrub',
  'mewls',
  'looms',
  'preen',
  'plugs',
  'cutes',
  'mices',
  'guine',
  'tunks',
  'ratty',
  'squea',
  'chirp',
  'tails',
  'squea',
  'preen',
  'flick',
  'sleep',
  'fetch',
  'cutes',
  'fishy',
  'claws',
  'knead',
  'tabby',
  'Quiet',
  'Yawns',
  'Swish',
  'Curly',
  'Climb',
  'Brave'
]
